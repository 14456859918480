<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'active'" class="text-nowrap">
          <b-badge v-if="props.row.active" variant="success" class="text-white"
            >เปิดใช้งาน</b-badge
          >
          <b-badge v-if="!props.row.active" variant="danger" class="text-white"
            >ไม่ใช้งาน</b-badge
          >
        </span>

        <span v-else-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="relief-warning"
            @click="$parent.editModal(props.row._id)"
            size="sm"
          >
            <!-- @click="edit(props.row._id)" -->
            Edit
          </b-button>
          <!-- <b-button
            class="ml-1"
            variant="relief-danger"
            @click="delete(props.row._id)"
            size="sm"
          >
            Delete
          </b-button> -->
          <confirm-delete
            @delete="Delete"
            :data="props.row._id"
          ></confirm-delete>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '25']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <!-- END pagination -->
    </vue-good-table>

    <!-- <add-modal :selected_id="selected_id" /> -->
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
// import AddModal from '../components/AddModal.vue'
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ConfirmDelete from "../../components/ConfirmDeleteButton.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // AddModal,
    ConfirmDelete,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: "ช่องทาง",
          field: "display_name",
        },

        {
          label: "เปิดใช้งาน",
          field: "active",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      selected_id: null,
      info: {},
    };
  },
  mounted() {
    this.$root.$on("reload", async () => {
      await this.loadData();
    });
  },
  methods: {
    view(id) {
      this.selected_id = id;
    },
    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    loadData() {
      this.$http({
        method: "GET",
        url: "/bookbank/list",
      }).then((x) => {
        this.rows = x.data.data.mix;
      });
    },
    Delete(x) {
      if (x.confirm) {
        this.$http({
          method: "DELETE",
          url: `/bookbank/${x.data}`,
        }).then(async (x) => {
          await this.loadData();
        });
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
