<template>
  <div>
    <b-modal
      id="add-modal"
      ref="add-modal"
      title="เพิ่มบัญชีธนาคาร"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <validation-observer ref="addBankAccount">
        <b-form @submit.prevent="addBankAccount">
          <!-- ชื่อที่แสดง -->
          <b-form-group label="ชื่อที่แสดง">
            <validation-provider
              #default="{ errors }"
              name="Bank Name"
              rules="required"
            >
              <b-form-input
                v-model="form.display_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ประเภท -->
          <b-form-group label="ประเภท">
            <b-form-select v-model="form.type" :options="type_options" />
          </b-form-group>
          <div v-if="form.type === 'transfer'">
            <!-- ชื่อธนาคาร -->
            <b-form-group label="ชื่อธนาคาร">
              <validation-provider
                #default="{ errors }"
                name="Bank Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.t_bank_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- ตัวย่อธนาคาร -->
            <b-form-group label="ตัวย่อธนาคาร">
              <validation-provider
                #default="{ errors }"
                name="Bank Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.t_bank_code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- สีธนาคาร -->
            <b-form-group label="สีธนาคาร">
              <validation-provider #default="{ errors }" name="Bank Color">
                <b-form-input
                  v-model="form.t_bank_color"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- เลขที่บัญชี -->
            <b-form-group label="เลขที่บัญชี">
              <validation-provider
                #default="{ errors }"
                name="Account Number"
                rules="required"
              >
                <b-form-input
                  v-model="form.t_account_number"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- ชื่อบัญชี -->
            <b-form-group label="ชื่อบัญชี">
              <validation-provider
                #default="{ errors }"
                name="Account Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.t_account_name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <!-- 999 -->
          <b-form-group label="999">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.code_999"
                name="some-radios5"
                :value="true"
              >
                Yes
              </b-form-radio>
              <b-form-radio
                v-model="form.code_999"
                name="some-radios5"
                :value="false"
              >
                No
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- Active -->
          <b-form-group label="Active">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.active"
                name="some-radios6"
                :value="true"
              >
                Yes
              </b-form-radio>
              <b-form-radio
                v-model="form.active"
                name="some-radios6"
                :value="false"
              >
                No
              </b-form-radio>
            </div>
          </b-form-group>

          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มบัญชีธนาคาร
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormSelect,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        active: true,
        code_999: false,
        type: null,
      },
      required,
      loading: false,
      type_options: [
        {
          text: "เงินสด",
          value: "cash",
        },
        {
          text: "โอนเข้าบัญชีธนาคาร",
          value: "transfer",
        },
        {
          text: "บัตรเครดิต",
          value: "creditcard",
        },
        {
          text: "กันวงเงิน",
          value: "card_verify",
        },
      ],
    };
  },
  methods: {
    validationForm() {
      this.$refs.addBankAccount.validate().then((success) => {
        console.log(success);
        if (success) {
          this.$http({
            method: "POST",
            url: "/bookbank/create",
            data: this.form,
          })
            .then((x) => {
              this.$root.$emit("reload");
              this.$bvModal.hide("add-modal");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: x.data.success ? "เพิ่มสมุดบัญชี" : "มีข้อผิดพลาด",
                  icon: x.data.success ? "CheckIcon" : "XIcon",
                  text: x.data.success ? "เพิ่มสมุดบัญชีแล้ว" : x.data.message,
                  variant: x.data.success ? "success" : "danger",
                },
              });
              this.form = {};
            })
            .catch((y) => {
              console.log(y);
            });
        }
      });
    },
  },
};
</script>
