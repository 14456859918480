<template>
    <div>
        <b-card>
            <div class="d-flex justify-content-end pb-1">
                <b-button size="sm" variant="relief-success" v-b-modal.add-modal><feather-icon icon="PlusIcon" /> เพิ่มบัญชีธนาคาร</b-button>
            </div>
            <list-table />
        </b-card>
        <edit-modal :selected_id="selected_id" />     
        <add-modal :selected_id="selected_id" />
    </div>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import ListTable from './components/ListTable.vue'
import AddModal from './components/AddModal.vue'
import EditModal from './components/EditModal.vue'

export default {
    components: {
        BCard,
        BCardText,
        BButton,
        ListTable,
        EditModal,
        AddModal
    },
    data() {
        return {
            selected_id:null,
            info:{},
            show_edit_modal:false,
            selected_id:null
        }
    },
    created() {
        
    },
    methods: {
        editModal(id){
            this.selected_id = id
            this.$bvModal.show('edit_modal')
        },
    },
}
</script>

